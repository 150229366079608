import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import SoftwareImage from '../images/services/software.jpg'
import InfrastructureImage from '../images/services/infrastructure.jpg'
import digitaloutreach from '../images/services/pragicts-digital-outreach-banner-revolution.jpg'
import cybersecops from '../images/services/pragicts-secops-banner-cybersecurity-complex.jpg'
import automation from '../images/services/pragicts-automation-banner-human-intervention.jpg'
import CloudImage from '../images/services/cloud.jpg'
import BgImage from '../images/bg/services-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import SmileyImage from '../images/solutions/smiley.jpg'


const ServicesPage = () => {
    return (
        <Layout pageTitle="PragICTS | Services" og_desc="Services by PragICTS">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>Services by  <br/><span> PragICTS</span></h2>
                                <p>Everything We Do</p>
                                <div className="horizonral-subtitle"><span>Services</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>What We Do</div>
                        <div className="container main-about">
                            <p>We meticulously articulate and consistently review our services to ensure effectiveness, quality, and customer satisfaction. Our team comprises professionals with relevant expertise and extensive experience in their respective fields. Quality assurance is paramount, upheld through pragmatic processes, active customer engagement, multi-tier reviews and oversight, regular feedback loops, adherence to engineering standards, and the deployment of cutting-edge technologies.</p>
                            <p>Our engagement models are thoughtfully tailored to accommodate the unique and specific needs of each customer. These configurations span from fixed-price arrangements to utilization-based, on-demand, or hybrid models, striking the optimal balance for cost-effectiveness, pragmatism, and seamless execution.</p>
                            <p>Our impeccable track record and extensive roster of satisfied clients stand as a testament to the effectiveness of our approach. We invite you to explore our services and experience firsthand the commitment to excellence that defines our organization.</p>
                        </div>
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                    <section className="dark-bg no-padding" id="services">
             <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Services </div>
            <div >
                <div className="section-title fl-wrap">
               
                    <h2>Our  <span> Services</span></h2>
          
                </div>
        <div className="fs-carousel-wrap fl-wrap full-height slider-carousel-wrap">
            <div className="fs-carousel fl-wrap full-height cur_carousel-slider-container">
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><Link target="_blank" to="https://softwaredevelopment.pragicts.com/">Software Development</Link></h3>
                        <Link target="_blank" to="https://softwaredevelopment.pragicts.com/" className="fs-carousel-link">More Details</Link>
                    </div>
                    <div className="carousle-item-number"><span>01.</span></div>
                    <div className="bg"  data-bg={SoftwareImage}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="https://itops.pragicts.com/">ITOps</a></h3>
                        <a target="_blank" href="https://itops.pragicts.com/" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>02.</span></div>
                    <div className="bg"  data-bg={InfrastructureImage}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="https://cloudops.pragicts.com/">CloudOps</a></h3>
                        <a target="_blank" href="https://cloudops.pragicts.com/" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>03.</span></div>
                    <div className="bg"  data-bg={CloudImage}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="https://cybersecops.pragicts.com/">CyberSecOps</a></h3>
                        <a target="_blank" href="https://cybersecops.pragicts.com/" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>04.</span></div>
                    <div className="bg"  data-bg={cybersecops}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="http://digitaloutreach.pragicts.com/">Digital Outreach</a></h3>
                        <a target="_blank" href="http://digitaloutreach.pragicts.com/" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>05.</span></div>
                    <div className="bg"  data-bg={digitaloutreach}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="https://automation.pragicts.com/">Automation</a></h3>
                        <a target="_blank" href="https://automation.pragicts.com/" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>06.</span></div>
                    <div className="bg"  data-bg={automation}></div>
                    <div className="overlay"></div>
                </div>
               
            </div>
            <div className="sp-cont sarr-contr sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
            <div className="sp-cont sarr-contr sp-cont-next"><i className="fal fa-arrow-right"></i></div>
            <div className="slider-nav-counter"></div>
        </div>
        </div>
    </section>
             {/*  <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={CognizantImage}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Website Design and Development </h3>
                                                <p>The Internet serves as the gateway connecting over 70% of the global population, offering a myriad of opportunities for online conveniences, customer engagement, sales and marketing endeavors, brand building, and business expansion. At its heart, websites constitute the fundamental cornerstone of an effective online presence strategy. </p>
                                                <p>PragICTS specializes in providing comprehensive solutions for every stage of the online presence journey, from strategic planning and conceptualization to website design, development, management, and ongoing support. Our tailored approach ensures a bespoke implementation from inception to execution.</p>
                                                <p>Our website development services encompass a wide array of offerings, including on-page SEO optimization, seamless integration with social and online platforms, implementation of content sharing functionalities with Open Graph compatibility, professional content creation, seamless integration with Google Analytics, assistance with Google Business registration, and comprehensive branding solutions including logo design, photography, videography, and more.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                        
                                            </div>
                                        </a>
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={EcommerceImage}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Web Application Development</h3>
                                                <p>Web browser-based applications offer a cost-effective solution for swift development and deployment, broad reach, seamless interoperability, streamlined maintenance and support, continuous enhancements, robust security measures, and centralized control.</p>
                                                <p>PragICTS specializes in crafting web browser-based applications that excel in user interface (UI) and user experience (UX), ensuring optimal usability and satisfaction. Our development services prioritize interoperability, enabling seamless integration with diverse systems and platforms. Additionally, our solutions are designed with extensibility and scalability in mind, accommodating growth and evolving requirements effortlessly.</p>
                                                <p>Whether for on-premise, cloud, or hybrid deployment environments, our team is equipped to deliver applications that meet stringent security standards while offering centralized control for efficient management.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                             
                                            </div>
                                        </a>
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={PayImage}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>eCommerce Implementation</h3>
                                                <p>The e-commerce landscape has burgeoned, surpassing the remarkable milestone of 14 trillion USD. It stands as a cornerstone of convenience, seamlessly accommodating the evolving lifestyles of today's society.</p>
                                                <p>PragICTS stands at the forefront, offering comprehensive services encompassing design, development, implementation, management, and support tailored for the effective implementation of e-commerce functionality. We leverage both commercial and open-source frameworks such as Bagisto, Open Cart, WooCommerce, among others, ensuring a versatile and robust solution to meet diverse business needs.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                      
                                            </div>
                                        </a>
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={PulseImage}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Mobile Application Development</h3>
                                                <p>Mobile applications consistently claim the top spot for delivering unparalleled user interface and user experience. PragICTS is dedicated to ensuring that businesses capitalize on this trend by offering cutting-edge mobile app design and development services for both iOS and Android platforms. We specialize in implementing native, web, and hybrid models to cater to diverse needs and preferences.</p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number">04.</span>
                                            
                                            </div>
                                        </a>
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={PulseImage}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Legacy Application Refactoring / Migration</h3>
                                                <p>While legacy software applications may boast proven functionality, they often present challenges in terms of extensibility and can incur significant costs for support and maintenance. PragICTS addresses these concerns head-on by providing expert services for legacy application refactoring. By leveraging current development technologies, we optimize legacy applications for enhanced performance, scalability, and productivity. Our solutions not only minimize costs but also ensure seamless deployment, whether on-premise or in the cloud.</p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number">05.</span>
                                            
                                            </div>
                                        </a>
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={SmileyImage}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Software Consulting</h3>
                                                <p>he realm of Information Technology (IT) is characterized by its complexity, disruptive innovation, and a plethora of choices and alternatives. It encompasses a broad spectrum of technologies spanning hardware, software, and cloud computing. Effective consulting in this dynamic landscape demands a depth and breadth of expertise and experience across all domains. </p>
                                                <p>At PragICTS, we recognize the multifaceted nature of IT and offer comprehensive consulting services that cover the entire spectrum of software, hardware, and cloud technologies. Our end-to-end approach ensures that clients receive sensible guidance and support tailored to their specific needs and objectives. Whether it's navigating software solutions, optimizing hardware infrastructure, or harnessing the power of cloud computing, PragICTS is committed to delivering strategic insights and practical solutions that drive success in the ever-evolving IT landscape.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">06.</span>
                                        
                                            </div>
                                        </a>
                                    </div>
                                









                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>*/}
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default ServicesPage
